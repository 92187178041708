







































































































































































































































#webhook-checkbox{
  .custom-control{
    margin-bottom: 15px;
    width: 20%;
  }
}
@media screen and (max-width:991px){
  #webhook-checkbox {
    .custom-control {
      width: 44%;
    }
  }
}
